import React, {CSSProperties} from 'react';
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import ARA_LOGO from "../images/about_ara_logo.png"
import {sectionLayout, sectionMargin} from "../utils/constants";
import MapLargeImageFluid from "./images/MapLargeImageFluid";
import MapShortImageFluid from "./images/MapShortImageFluid";

function About() {
    const {t} = useTranslation()

    const sponsorImageStyle: CSSProperties = {
        margin: '0 0.5rem'
    }

    const imageLayout = {
        xs: {span: 0},
        md: {span: 24},
    }

    const mobileIimageLayout = {
        xs: {span: 24},
        md: {span: 0},
    }

    return (
        <Row id='about' style={{backgroundColor: '#F8F9FA', textAlign: 'center'}}>
            <Col {...sectionLayout} style={{marginTop: sectionMargin}}>
                <Col span={24}>
                    <div>
                        <img src={ARA_LOGO}
                             style={{width: '5%', minWidth: '62px', marginBottom: '2rem'}}
                             alt="ara_log"/>
                    </div>

                    <h2 className='section-title'>
                        {t('about.title')}
                    </h2>

                    <p className='section-description'>
                        {t('about.description')}
                    </p>
                </Col>
                <Col {...imageLayout} style={{margin: 'auto'}}>
                    <MapLargeImageFluid/>
                </Col>
                <Col {...mobileIimageLayout}
                     style={{margin: 'auto'}}>
                    <MapShortImageFluid/>
                </Col>

                {/*<Separator/>*/}
                {/*<Row justify='center'>*/}
                {/*    <Col span={24}>*/}
                {/*        <h3 className='section-title'>*/}
                {/*            {t('about.sponsors.title')}*/}
                {/*        </h3>*/}

                {/*        <p className='section-description'>*/}
                {/*            {t('about.sponsors.description')}*/}
                {/*        </p>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <img src={SPONSOR_ROMPREST_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_REBU_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_SOMA_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_ROMPREST_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_ROSAL_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_RECOLAMP_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*        <img src={SPONSOR_SOMA_IMAGE} alt="" style={sponsorImageStyle}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Col>
        </Row>

    );
}

function Separator() {
    return (
        <div style={{
            width: '100%',
            margin: '3rem 0',
            borderBottom: '1px solid #E3E6E8',
        }}>

        </div>
    )
}

export default About;
