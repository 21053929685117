import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"


const MapShortImageFluid = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about_map_background_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 762, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

    return <img
        src={data.placeholderImage.childImageSharp.fluid.src}
        style={{width: '100%'}}
        alt={'romania locations map'}/>
}

export default MapShortImageFluid
