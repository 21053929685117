import React from 'react';
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import CALENDAR_ICON from "../images/calendar_icon.png"
import GUIDE_ICON from "../images/guide_icon.png"
import HELP_ICON from "../images/help_icon.png"
import HELPDESK_ICON from "../images/helpdesk_icon.png"
import NOTIFICATIONS_ICON from "../images/notifications_icon.png"
import {sectionLayout, sectionMargin} from "../utils/constants";

function Functionalities() {
    const {t} = useTranslation()

    const defaultMargin = '3rem'
    return (
        <Row id='functionalities'>
            <Col {...sectionLayout}>
                <Row style={{marginTop: defaultMargin}}>
                    <Col {...{
                        xs: {span: 22},
                        sm: {span: 18},
                        lg: {span: 14},
                        xl: {span: 10},
                    }}
                         style={{textAlign: 'left'}}>
                        <h3 className='section-title'>
                            {t('functionalities.title')}
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <FunctionalityItem
                        icon={CALENDAR_ICON}
                        title={t('functionalities.calendar.title')}
                        description={t('functionalities.calendar.description')}/>
                    <FunctionalityItem
                        icon={GUIDE_ICON}
                        title={t('functionalities.recycling_guide.title')}
                        description={t('functionalities.recycling_guide.description')}/>
                    <FunctionalityItem
                        icon={NOTIFICATIONS_ICON}
                        title={t('functionalities.notifications.title')}
                        description={t('functionalities.notifications.description')}/>
                    <FunctionalityItem
                        icon={HELP_ICON}
                        title={t('functionalities.support.title')}
                        description={t('functionalities.support.description')}/>
                    <FunctionalityItem
                        icon={HELPDESK_ICON}
                        title={t('functionalities.requests.title')}
                        description={t('functionalities.requests.description')}/>
                </Row>
            </Col>
        </Row>
    );
}

interface FunctionalityItemProps {
    title: string
    description: string
    icon: string
}

function FunctionalityItem(props: FunctionalityItemProps) {
    const {title, description, icon} = props
    return (
        <Col
            {...{
                xs: {span: 24},
                sm: {span: 12},
                xl: {span: 8},
            }}
            style={{marginTop: sectionMargin, textAlign: 'left', paddingRight: '3rem'}}>
            <img src={icon}
                 style={{
                     width: '2rem',
                     display: 'block'
                 }}
                 alt=""/>
            <h4 className='func-info-title'>
                {title}
            </h4>
            <p className='func-info-description'>
                {description}
            </p>
        </Col>
    )
}

export default Functionalities;
