import React from "react"
import "../styles/index.css"
import AppWrapper from "../components/AppWrapper"
import HelpDesk from "../components/HelpDesk"
import RecyclingGuide from "../components/RecyclingGuide"
import Functionalities from "../components/Functionalities"
import About from "../components/About"
import Intro from "../components/Intro"
import Seo from "../components/seo";


const IndexPage = () => (
    <div>
        <Seo title={'Ara | Asistentul tău de reciclare'}/>
        <AppWrapper>
            <Intro/>
            <About/>
            <Functionalities/>
            <RecyclingGuide/>
            <HelpDesk/>
          {/*<Contact/>*/}
        </AppWrapper>
    </div>
)

export default IndexPage
