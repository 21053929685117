import React, {CSSProperties} from 'react';
import {Card, Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import INFO_GARBAGE_TRUCK from '../images/info_garbage_truck.png'
import INFO_HAZARDOUS_MASK from '../images/info_hazardous_mask.png'
import INFO_WASTE_SEPARATION from '../images/info_waste_separation.png'
import INFO_BUILDING from '../images/info_buildings.png'
import INFO_NOTIFICATIONS from '../images/info_notifications.png'
import INFO_RECYCLING from '../images/info_recycling.png'
import {sectionLayout, sectionMargin} from "../utils/constants";

function HelpDesk() {
    const {t} = useTranslation()

    const recycingTips = [
        {
            icon: INFO_WASTE_SEPARATION,
            info: t('help_desk.tips.tip_1')
        },
        {
            icon: INFO_HAZARDOUS_MASK,
            info: t('help_desk.tips.tip_2')
        },
        {
            icon: INFO_GARBAGE_TRUCK,
            info: t('help_desk.tips.tip_3')
        },
        {
            icon: INFO_RECYCLING,
            info: t('help_desk.tips.tip_4')
        },
        {
            icon: INFO_BUILDING,
            info: t('help_desk.tips.tip_5')
        },
        {
            icon: INFO_NOTIFICATIONS,
            info: t('help_desk.tips.tip_6')
        }
    ]

    const infoComponentStyle: CSSProperties = {
        textAlign: 'center',
        minHeight: '15rem',
        marginTop: '16px',
        border: '1px solid #C8CCD0',
        boxSizing: 'border-box',
        borderRadius: '4px'
    }

    const infoLayout = {
        xs: {span: 20, offset: 2},
        sm: {span: 12, offset: 0},
        xl: {span: 8}
    }

    return (
        <Row id='helpdesk' style={{paddingTop: sectionMargin}}>
            <Col {...sectionLayout}>
                <Row>
                    <Col {...{
                        xs: {span: 24},
                        sm: {span: 18},
                        lg: {span: 12},
                    }}
                         style={{textAlign: 'left'}}>
                        <h3 className='section-title'>
                            {t('help_desk.title')}
                        </h3>

                        <p className='section-description'>
                            {t('help_desk.description')}
                        </p>
                        <p className='section-description' style={{display: "block"}}>
                            {t('help_desk.tips_title')}
                        </p>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {recycingTips
                        .map((recyclingTip, index) => (
                            <Col {...infoLayout}
                                 key={index}>
                                <Card
                                    style={infoComponentStyle}>
                                    <div>
                                        <img src={recyclingTip.icon}
                                             style={{
                                                 width: '25%',
                                                 minWidth: '4rem',
                                                 marginTop: '1rem'
                                             }}
                                             alt=""/>
                                    </div>
                                    <div style={{margin: "2rem 1rem 0 1rem"}}>
                                        <p>
                                            {recyclingTip.info}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Col>
        </Row>
    );
}

export default HelpDesk;
