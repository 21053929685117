import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"


const MapLargeImageFluid = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about_map_background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1338, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return <img
        src={data.placeholderImage.childImageSharp.fluid.src}
        style={{width: '100%'}}
        alt={'romania locations map'}/>
}

export default MapLargeImageFluid
