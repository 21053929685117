import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"


const AraPhoneImageFluid = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ara_intro_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 1587, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

    return <img
        src={data.placeholderImage.childImageSharp.fluid.src}
        style={{width: '100%'}}
        alt={'ara app image'}/>
}

export default AraPhoneImageFluid
