import React, {CSSProperties, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Modal, Row} from "antd";
import BUTTON_APP_STORE from "../images/button_app_store.svg"
import BUTTON_GOOGLE_PLAY from "../images/button_google_play.svg"
import BUTTON_PLAY_VIDEO from "../images/button_play_video.svg"
import {config} from "../config";
import {sectionMargin} from "../utils/constants";
import {detectiOS, detectMobile} from "../utils";
import AraPhoneImageFluid from "./images/AraPhoneImageFluid";
import AraPhoneShortImageFluid from "./images/AraPhoneShortImageFluid";

interface VideoParams {
    width: number
    height: number
}

function Intro() {
    const {t} = useTranslation()

    function getPhoneImageSrc() {
        const isShortImage = window.innerWidth < 768
        return isShortImage ? <AraPhoneShortImageFluid/> : <AraPhoneImageFluid/>
    }

    const initialVideoParams: VideoParams = {
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.7
    }

    const initialPhoneImage = getPhoneImageSrc()

    const [isVideoOpen, setVideoOpen] = useState<boolean>(false)
    const [videoParams, setVideoParams] = useState(initialVideoParams)
    const [imageSrc, setImageSrc] = useState(initialPhoneImage)

    const isMobile = detectMobile()
    const isIOS = detectiOS()

    function handleVideoResize() {
        const params = {
            width: window.innerWidth * 0.8,
            height: window.innerHeight * 0.7
        }
        setVideoParams(params)
    }

    function handleImageResize() {
        const newPhoneImage = getPhoneImageSrc()
        setImageSrc(newPhoneImage)
    }

    useEffect(() => {
        function handleWindowsResize() {
            handleVideoResize()
            handleImageResize()
        }

        window.addEventListener('resize', handleWindowsResize, true)
        return () => {
            window.removeEventListener('resize', handleWindowsResize, true)
        }
    }, [handleVideoResize, handleImageResize])


    function handleCloseVideo() {
        setVideoOpen(false)
    }

    const buttonStyle: CSSProperties = {
        padding: '0.5rem',
        cursor: 'pointer'
    }

    return (
        <Row id='intro' align='middle'>
            <Col xs={{span: 22, offset: 1}}
                 sm={{span: 18, offset: 1}}
                 md={{span: 7, offset: 2}}
                 lg={{span: 7, offset: 5}}>
                <div style={{textAlign: 'left', marginTop: '8rem'}}>
                    <h2 style={{fontSize: '2.25rem', fontWeight: 600}}>
                        {t('intro.title')}
                    </h2>

                    <p style={{fontSize: '1.125rem', color: '#505962'}}>
                        {t('intro.description')}
                    </p>
                </div>
                <Row style={{marginBottom: sectionMargin}}>
                    {(!isMobile || isIOS) &&
                    <LinkButton link={config.links.appStore}
                                src={BUTTON_APP_STORE}
                                alt="button_app_store"/>}
                    {!isIOS &&
                    <LinkButton link={config.links.googlePlay}
                                style={{
                                    ...buttonStyle
                                }}
                                src={BUTTON_GOOGLE_PLAY}
                                alt="button_google_play"/>}

                    <LinkButton link={''}
                                src={BUTTON_PLAY_VIDEO}
                                onClick={(event: MouseEvent) => {
                                    event.preventDefault()
                                    setVideoOpen(true)
                                }}
                                alt="button_play_video"/>
                </Row>
            </Col>
            <Col xs={{span: 24, offset: 0}}
                 sm={{span: 24, offset: 0}}
                 md={{span: 15, offset: 0}}
                 lg={{span: 12, offset: 0}}>

                {imageSrc}
            </Col>

            <Modal
                title={t('intro.modal_title')}
                centered
                maskClosable
                width={'80%'}
                visible={isVideoOpen}
                footer={null}
                onOk={handleCloseVideo}
                onCancel={handleCloseVideo}
                destroyOnClose={true}
            >
                <iframe width={videoParams.width} height={videoParams.height}
                        title={t('intro.modal_title')}
                        src={config.links.video}>
                </iframe>
            </Modal>

        </Row>
    );
}

interface LinkButtonProps {
    link: string
    src: string
    alt?: string
    style?: CSSProperties
    onClick?: Function
}

function LinkButton(props: LinkButtonProps) {
    const {link, src, alt = "", style = {}, onClick = () => null} = props

    const buttonStyle: CSSProperties = {
        padding: '0.5rem',
        cursor: 'pointer',
        ...style
    }

    return (
        <Col
            xs={{span: 12}}
            sm={{span: 8}}
            md={{span: 12}}
            xl={{span: 8}}
            style={buttonStyle}>
            <a href={link}
               onClick={(event) => onClick(event)}>
                <img
                    style={{width: '100%', marginRight: '1rem'}}
                    src={src}
                    alt={alt}/>
            </a>
        </Col>
    )
}

export default Intro;
