import React, {CSSProperties} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Row, Tabs} from "antd";
import {sectionLayout, sectionMargin} from "../utils/constants";

interface TabItem {
    title: string;
    description: string;
    items: string[]
}

function RecyclingGuide() {
    const {t} = useTranslation()

    const rezidualText: TabItem = t('recycling_guide.tabs.rezidual', {returnObjects: true})
    const bioText: TabItem = t('recycling_guide.tabs.bio', {returnObjects: true})
    const glassText: TabItem = t('recycling_guide.tabs.glass', {returnObjects: true})
    const plasticText: TabItem = t('recycling_guide.tabs.plastic', {returnObjects: true})
    const paperText: TabItem = t('recycling_guide.tabs.paper', {returnObjects: true})

    const rezidual = {
        ...rezidualText,
        colors: {fillColor: "#505962", strokeColor: "#2E3338"}
    }
    const bio = {
        ...bioText,
        colors: {fillColor: '#995900', strokeColor: '#2E3338'}
    }
    const glass = {
        ...glassText,
        colors: {fillColor: '#30a000', strokeColor: '#194905'}
    }
    const plastic = {
        ...plasticText,
        colors: {fillColor: '#FFBF00', strokeColor: '#E69900'}
    }
    const paper = {
        ...paperText,
        colors: {fillColor: '#0095FF', strokeColor: '#0A66C2'}
    }

    const tabs = [rezidual, bio, glass, plastic, paper]

    return (
        <Row id="recycling_guide"
             style={{backgroundColor: '#F8F9FA', marginTop: sectionMargin}}>
            <Col {...sectionLayout}>
                <Row>
                    <Col span={24} style={{textAlign: 'left', marginTop: sectionMargin}}>
                        <h3 className='section-title'>
                            {t('recycling_guide.title')}
                        </h3>

                        <p className='section-description'>
                            {t('recycling_guide.description')}
                        </p>
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col span={24}>
                        <Tabs>
                            {tabs.map((tabItem, index) => (
                                <Tabs.TabPane tab={tabItem.title} key={index}>
                                    <Row style={{marginTop: '1.5rem'}}>
                                        <Col {...{
                                            xs: {span: 24},
                                            sm: {span: 8}
                                        }}>
                                            <ContainerComponent
                                                colors={tabItem.colors}
                                                description={tabItem.description}/>
                                        </Col>
                                        <Col {...{xs: {span: 0}, sm: {span: 2}}}
                                             style={{
                                                 textAlign: 'left',
                                                 paddingLeft: '0.5rem',
                                                 minHeight: '30rem'
                                             }}>
                                            <ContentSeparation/>
                                        </Col>
                                        <Col {...{xs: {span: 24}, sm: {span: 0}}}>
                                            <div style={{
                                                height: '2px',
                                                margin: '2rem 0',
                                                backgroundColor: '#E3E6E8'
                                            }}/>
                                        </Col>
                                        <Col {...{
                                            xs: {span: 24},
                                            sm: {span: 14}
                                        }}
                                             style={{
                                                 textAlign: 'left',
                                                 minHeight: '28rem'
                                             }}>
                                            {(tabItem.items || []).map((infoItem, index) => (
                                                <p key={index}
                                                   style={{fontSize: '0.875rem'}}>
                                                    {infoItem}
                                                </p>
                                            ))}
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </Col>
                </Row>
            </Col>

        </Row>
    );
}

export default RecyclingGuide;

function ContentSeparation() {
    return (
        <svg
            width="15" height="401" viewBox="0 0 15 401" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" width="1" height="401" fill="#C8CCD0"/>
            <path d="M2.99411 43L14.9941 31L2.99411 19L2 19.9941L13.0059 31L2 42.0059L2.99411 43Z" fill="#C8CCD0"/>
            <path d="M0.994112 43L12.9941 31L0.994112 19L0 19.9941V42.0059L0.994112 43Z" fill="#F8F9FA"/>
        </svg>
    )
}

interface ContainerComponentProps {
    colors: BinComponentProps
    description: string
}

function ContainerComponent(props: ContainerComponentProps) {
    const {colors, description} = props
    return (
        <Row>
            <Col span={6}>
                <BinComponent {...colors}/>

            </Col>
            <Col span={18}
                 style={{
                     textAlign: 'left',
                     margin: 'auto 0',
                     paddingLeft: '1rem',
                 }}>
                    <span style={{
                        fontWeight: 600,
                        fontSize: '1rem'
                    }}>{description}</span>
            </Col>
        </Row>
    )
}

interface BinComponentProps {
    strokeColor: CSSProperties['color']
    fillColor: CSSProperties['color']
}

function BinComponent({strokeColor, fillColor}: BinComponentProps) {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.4326 0H20.4072C19.8545 0 19.4072 0.44775 19.4072 1V3.875H14.3125C14.0107 3.875 13.7256 4.01074 13.5361 4.24512L9.9887 8.61817H7.04688C6.49414 8.61817 6.04688 9.06592 6.04688 9.61817V14.4541C6.04688 15.0064 6.49414 15.4541 7.04688 15.4541H9.45337L12.874 63.0718C12.9111 63.5947 13.3467 64 13.8711 64H43.9336H43.957C43.958 64 43.959 64 43.9609 64C47.625 63.9868 50.6064 60.9932 50.6064 57.3271C50.6064 54.3569 48.5997 51.7642 45.829 50.9509L48.3748 15.4541H55.5352C57.4199 15.4541 58.9531 13.9209 58.9531 12.0361C58.9531 10.1514 57.4199 8.61816 55.5352 8.61816H47.8507L44.29 4.24365C44.1006 4.01026 43.8154 3.875 43.5146 3.875H38.4326V1C38.4326 0.44776 37.9853 0 37.4326 0Z"
                fill={fillColor}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M37.4326 0H20.4072C19.8545 0 19.4072 0.44775 19.4072 1V3.875H14.3125C14.0107 3.875 13.7256 4.01074 13.5361 4.24512L9.9887 8.61817H7.04688C6.49414 8.61817 6.04688 9.06592 6.04688 9.61817V14.4541C6.04688 15.0064 6.49414 15.4541 7.04688 15.4541H9.45337L12.874 63.0718C12.9111 63.5947 13.3467 64 13.8711 64H43.9336H43.957H43.9609C47.625 63.9868 50.6064 60.9932 50.6064 57.3271C50.6064 54.3569 48.5997 51.7642 45.829 50.9509L48.3748 15.4541H55.5352C57.4199 15.4541 58.9531 13.9209 58.9531 12.0361C58.9531 10.1514 57.4199 8.61816 55.5352 8.61816H47.8507L44.29 4.24365C44.1006 4.01026 43.8154 3.875 43.5146 3.875H38.4326V1C38.4326 0.44776 37.9853 0 37.4326 0ZM21.4072 2H36.4326V3.875H21.4072V2ZM43.9531 62C46.5195 61.9907 48.6064 59.8945 48.6064 57.3272C48.6064 54.7711 46.5014 52.6655 43.9336 52.6655C41.3633 52.6655 39.2725 54.7568 39.2725 57.3272C39.2725 59.9038 41.3633 62 43.9336 62C43.9371 62 43.9403 62.001 43.9435 62.002C43.9466 62.003 43.9497 62.0039 43.9531 62.004V62ZM43.8447 50.666C40.1958 50.7141 37.2725 53.7189 37.2725 57.3271C37.2725 59.1226 38.024 60.8177 39.1826 62H14.8018L11.458 15.4541H46.3701L43.8447 50.666ZM55.5352 13.4541C56.3174 13.4541 56.9531 12.8179 56.9531 12.0361C56.9531 11.2544 56.3174 10.6182 55.5352 10.6182H8.04688V13.4541H55.5352ZM45.2715 8.61817H12.5635L14.7891 5.875H43.0391L45.2715 8.61817ZM43.9297 54.4439C42.3418 54.4439 41.0508 55.7349 41.0508 57.3223C41.0508 58.9209 42.3418 60.2217 43.9297 60.2217H43.9502H43.959C45.542 60.2075 46.8291 58.9067 46.8291 57.3223C46.8291 55.681 45.4048 54.4439 43.9297 54.4439ZM44.8291 57.3223C44.8291 57.814 44.4307 58.2173 43.9414 58.2217H43.9297C43.4453 58.2217 43.0508 57.8184 43.0508 57.3223C43.0508 56.8555 43.417 56.4722 43.8779 56.4453C43.8926 56.4514 43.9236 56.4564 43.9656 56.4632C44.2098 56.5026 44.8291 56.6027 44.8291 57.3223Z"
                  fill={strokeColor}/>
        </svg>

    )
}
